/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { MenuTriggerWrapper } from '../Sidebar/sidebar.elements';
import BellIcon from 'components/UI/atoms/Icons/BellIcon';
import MenuIcon from 'components/UI/atoms/Icons/MenuIcon';
import Image from 'components/UI/atoms/Image';
import Modal from 'antd/es/modal/Modal';
import LogoConfluidity from 'components/assets/images/Logo_Confluidty.png';
import { PlusCircleIcon, SearchIcon } from 'components/UI/atoms/Icons';

export default function NavbarMobile({ onClick, ref }) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <>
      <MenuTriggerWrapper className="content-center">
        <button
          onClick={onClick}
          ref={ref}
          data-drawer-target="default-sidebar"
          data-drawer-toggle="default-sidebar"
          aria-controls="default-sidebar"
          type="button"
          className="inline-flex items-center p-1 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
        >
          <span className="sr-only">Open sidebar</span>
          <MenuIcon />
        </button>
        <div className="text-white">
          <button onClick={showModal} className="mt-1">
            <BellIcon />
          </button>
        </div>
        <Image
          src={LogoConfluidity}
          alt="logo confluidity"
          className="cursor-pointer ml-auto"
        />
        <button className="mt-1 ml-auto">
          <SearchIcon width="20" height="20" />
        </button>
        <button className="mt-1">
          <PlusCircleIcon color="black" />
        </button>
      </MenuTriggerWrapper>

      <Modal
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        closable={false}
        footer={null}
        className="p-0"
      >
        <div>
          <div className="flex justify-between">
            <div className="font-medium">Document Already Analyze</div>
            <div>10:24</div>
          </div>
          <div className="text-xs mt-1">
            “Report Confluidity Workshop 2024.pdf” has analyze
          </div>
        </div>
      </Modal>
    </>
  );
}
