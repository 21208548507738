import React, { Suspense, useContext } from 'react';
import Sidebar from './Sidebar';
import {
  ContentWrapper,
  MenuWrapper,
  Wrapper,
} from 'components/UI/atoms/Wrapper';
import Navbar from './Navbar';
import { Outlet } from 'react-router-dom';
import { GlobalStateContext } from 'contexts/GlobalStateContext';

const RootTemplate = () => {
  const responsiveSide = useContext(GlobalStateContext);
  return (
    <React.Fragment>
      <Wrapper>
        <MenuWrapper
          className={`${responsiveSide ? 'w-20 small-nav' : 'w-56'}`}
        >
          <Sidebar user={null} />
        </MenuWrapper>
        <Navbar />
        <ContentWrapper className="transition-all duration-500 w-full h-full">
          <Suspense fallback={`Loading...`}>
            <Outlet />
          </Suspense>
        </ContentWrapper>
      </Wrapper>
    </React.Fragment>
  );
};

export default RootTemplate;
