import Routes from './routers';
import * as Sentry from '@sentry/react';
import './App.css';
import { GlobalStateProvider } from 'contexts/GlobalStateContext';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { MessageProvider } from 'contexts/GlobalStateMessage';

const App = () => {
  return (
    <GlobalStateProvider>
      <MessageProvider>
        <ToastContainer />
        <Routes />
      </MessageProvider>
    </GlobalStateProvider>
  );
};

export default Sentry.withProfiler(App);
