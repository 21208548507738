// GlobalStateContext.js
import React, { createContext, useState } from 'react';

// Membuat context
export const GlobalStateContext = createContext();
export const GlobalStateUpdaterContext = createContext();

// Membuat provider untuk context
export const GlobalStateProvider = ({ children }) => {
  const [responsiveSide, setResponsiveSide] = useState(false);
  // const [message, setMessage] = useState(null);

  return (
    <GlobalStateContext.Provider value={responsiveSide}>
      {/* <GlobalStateContext.Provider
      value={{ message, setMessage, responsiveSide, setResponsiveSide }}
    > */}
      <GlobalStateUpdaterContext.Provider value={setResponsiveSide}>
        {children}
      </GlobalStateUpdaterContext.Provider>
    </GlobalStateContext.Provider>
  );
};
