export class Config {
  static REACT_APP_SENTRY_DSN = window.REACT_APP_SENTRY_DSN;
  static REACT_APP_SENTRY_ON_ERROR = window.REACT_APP_SENTRY_ON_ERROR;
  static REACT_APP_SENTRY_SAMPLE_RATE = window.REACT_APP_SENTRY_SAMPLE_RATE;
  static REACT_APP_SENTRY_ENV = window.REACT_APP_SENTRY_ENV;

  static REACT_APP_URL = window.REACT_APP_URL;
  static REACT_APP_SOCKET_URL = window.REACT_APP_SOCKET_URL;
  static REACT_APP_BACKEND_URL = window.REACT_APP_BACKEND_URL;
}
