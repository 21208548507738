import React, { useContext, useState } from 'react';
import {
  ArrowDownIcon,
  ArrowUpIcon,
  // DeleteIcon,
  DeleteMemoryLogIcon,
  DotHorizontalIcon,
  ShareIcon,
} from '../atoms/Icons';
import {
  deleteItemHistoryChat,
  getPaginateChat,
  shareMessage,
} from 'services/chat/chat';
import { useQuery, useMutation } from 'react-query';
import { useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { MessageContext } from 'contexts/GlobalStateMessage';
import { checkRoleAuth } from 'utils/authUtils';
import { Popover } from 'antd';
import ModalShareCHat from './ModalShareChat';
import ModalDelete from '../atoms/ModalDelete';
import { Config } from 'utils/initialConfig';

const CardItemHistoryChat = () => {
  const role = checkRoleAuth();
  const navigate = useNavigate();
  const { chatId } = useParams();
  const [maxHeight, setMaxHeight] = useState(20);
  const [hoveredItem, setHoveredItem] = useState(null);
  const { loadingMessage } = useContext(MessageContext);
  const [openPopoverId, setOpenPopoverId] = useState(null);
  const [openShareChat, setOpenShareChat] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [idChat, setIdChat] = useState(null);
  const [labelChat, setLabelChat] = useState(null);
  const [urlShareChat, setUrlShareChat] = useState(null);

  const handleModalShareChat = async (id) => {
    const formDataIdChat = { chat_id: parseInt(id) };
    try {
      const res = await shareMessage(formDataIdChat);
      if (res) {
        setUrlShareChat(
          `${Config?.REACT_APP_URL}/chat-share/${res?.data?.token}`
        );
        setOpenShareChat(!openShareChat);
      }
    } catch (error) {
      toast.error(error);
      refetch();
    }
  };

  const handleCloseModalShareChat = () => {
    setOpenShareChat(false);
  };

  const handleOpenChange = (id) => {
    setOpenPopoverId((prevOpenId) => (prevOpenId === id ? null : id));
  };

  const isPopoverOpen = (id) => openPopoverId === id;

  const { data: dataPaginateChats, refetch } = useQuery(
    ['fetchPaginationChats'],
    () => getPaginateChat(),
    {
      enabled: true,
    }
  );

  const itemHistoryChatRecent =
    dataPaginateChats?.data?.recent_chats?.map((item) => ({
      id: item?.id,
      label: item?.name,
      path: `chatbot/${item?.id}`,
      active: chatId == item?.id,
    })) || [];

  const itemHistoryChatOld =
    dataPaginateChats?.data?.old_chats?.map((item) => ({
      id: item?.id,
      label: item?.name,
      path: `chatbot/${item?.id}`,
      active: chatId == item?.id,
    })) || [];

  const mutation = useMutation((chatId) => deleteItemHistoryChat(chatId), {
    onSuccess: (data) => {
      setOpenDelete(false);
      toast.success(data?.message);
      refetch();
    },
    onError: (error) => {
      setOpenDelete(false);
      toast.error(error);
      refetch();
    },
  });

  const DeleteItemHistoryChat = (chatId) => {
    mutation.mutate(chatId);
  };

  const handleModalDelete = (id, label) => {
    setIdChat(id);
    setLabelChat(label);
    setOpenDelete(!openDelete);
  };

  return (
    <>
      <div
        className={`mt-5 overflow-y-auto`}
        style={{ maxHeight: `${maxHeight}rem` }}
      >
        <div className="pl-6">
          {dataPaginateChats?.data?.recent_chats && (
            <span className="font-normal text-sm text-[#98A2B3]">Recent</span>
          )}
        </div>
        {itemHistoryChatRecent?.map((child) => (
          <div
            key={child.path}
            className={`pl-0 flex items-center my-6 px-4 ml-4`}
          >
            <div
              className={`w-full flex flex-wrap item-center font-normal text-sm ${child.active ? 'text-[#5D42BD] bg-[#EFF1FE] py-2 px-2 rounded-lg' : !child.active && (hoveredItem === child.path || isPopoverOpen(child.id)) ? 'text-[#5D42BD] ml-2' : 'text-[#344054] ml-2'} '}
            `}
              onMouseEnter={() => !child.active && setHoveredItem(child.path)}
              onMouseLeave={() =>
                !child.active &&
                !isPopoverOpen(child.id) &&
                setHoveredItem(null)
              }
            >
              <div
                onClick={
                  !loadingMessage ? () => navigate(child.path) : () => {}
                }
                className={`${!child.active && (hoveredItem === child.path || isPopoverOpen(child.id)) && 'w-4/5'} ${!loadingMessage && 'cursor-pointer'}`}
              >
                {!child.active &&
                (hoveredItem === child.path || isPopoverOpen(child.id))
                  ? child.label.length > 15
                    ? child.label.substring(0, 15) + '...'
                    : child.label
                  : child.label.length > 20
                    ? child.label.substring(0, 20) + '...'
                    : child.label}
              </div>
              <div
                className={`w-auto flex flex-wrap items-center pt-1 pl-1 ml-2 ${!loadingMessage && 'cursor-pointer'}`}
              >
                {!child.active &&
                  (hoveredItem === child.path || isPopoverOpen(child.id)) && (
                    <Popover
                      content={
                        <div className="w-24 flex flex-wrap space-y-2">
                          <div
                            onClick={() => {
                              handleModalShareChat(child.id);
                            }}
                            className="w-full flex items-center gap-1 cursor-pointer"
                          >
                            <ShareIcon width="16" height="18" color="#000000" />{' '}
                            <span>Share Chat</span>
                          </div>{' '}
                          <div
                            onClick={() => {
                              handleModalDelete(child.id, child.label);
                            }}
                            className="w-full flex items-center gap-1 cursor-pointer"
                          >
                            <DeleteMemoryLogIcon
                              width="14"
                              height="14"
                              color="#000000"
                            />{' '}
                            <span>Delete Chat</span>
                          </div>{' '}
                        </div>
                      }
                      trigger="click"
                      open={isPopoverOpen(child.id)} // Only open if popover ID matches
                      onOpenChange={() =>
                        handleOpenChange(child.id, child.label)
                      } // Manage open state by item ID
                      placement="rightTop"
                    >
                      <DotHorizontalIcon />
                    </Popover>
                  )}
              </div>
            </div>
          </div>
        ))}
        <div className="pl-6">
          {dataPaginateChats?.data?.old_chats && (
            <span className="font-normal text-sm text-[#98A2B3]">Old</span>
          )}
        </div>
        {itemHistoryChatOld.map((child) => (
          <div
            key={child.path}
            className={`pl-0 flex items-center my-6  px-4 ml-4`}
          >
            <div
              className={`w-full flex flex-wrap item-center font-normal text-sm ${child.active ? 'text-[#5D42BD] bg-[#EFF1FE] py-2 px-2 rounded-lg' : !child.active && (hoveredItem === child.path || isPopoverOpen(child.id)) ? 'text-[#5D42BD] ml-2' : 'text-[#344054] ml-2'} '}
            `}
              onMouseEnter={() => !child.active && setHoveredItem(child.path)}
              onMouseLeave={() =>
                !child.active &&
                !isPopoverOpen(child.id) &&
                setHoveredItem(null)
              }
            >
              <div
                onClick={
                  !loadingMessage ? () => navigate(child.path) : () => {}
                }
                className={`${!child.active && (hoveredItem === child.path || isPopoverOpen(child.id)) && 'w-4/5'} ${!loadingMessage && 'cursor-pointer'}`}
              >
                {!child.active &&
                (hoveredItem === child.path || isPopoverOpen(child.id))
                  ? child.label.length > 15
                    ? child.label.substring(0, 15) + '...'
                    : child.label
                  : child.label.length > 20
                    ? child.label.substring(0, 20) + '...'
                    : child.label}
              </div>
              <div
                className={`w-auto flex flex-wrap items-center pt-1 pl-1 ml-2 ${!loadingMessage && 'cursor-pointer'}`}
              >
                {!child.active &&
                  (hoveredItem === child.path || isPopoverOpen(child.id)) && (
                    <Popover
                      content={
                        <div className="w-24 flex flex-wrap space-y-2">
                          <div
                            onClick={() => {
                              handleModalShareChat(child.id);
                            }}
                            className="w-full flex items-center gap-1 cursor-pointer"
                          >
                            <ShareIcon width="16" height="18" color="#000000" />{' '}
                            <span>Share Chat</span>
                          </div>{' '}
                          <div
                            onClick={() => {
                              handleModalDelete(child.id, child.label);
                            }}
                            className="w-full flex items-center gap-1 cursor-pointer"
                          >
                            <DeleteMemoryLogIcon
                              width="14"
                              height="14"
                              color="#000000"
                            />{' '}
                            <span>Delete Chat</span>
                          </div>{' '}
                        </div>
                      }
                      trigger="click"
                      open={isPopoverOpen(child.id)} // Only open if popover ID matches
                      onOpenChange={() => handleOpenChange(child.id)} // Manage open state by item ID
                      placement="rightTop"
                    >
                      <DotHorizontalIcon />
                    </Popover>
                  )}
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="w-full flex justify-center">
        {(dataPaginateChats?.data?.recent_chats ||
          dataPaginateChats?.data?.old_chats) && (
          <div className="w-auto flex flex-wrap items-center gap-x-2">
            <span className="font-semibold text-sm">See more</span>{' '}
            {maxHeight === 20 ? (
              <span
                onClick={() => setMaxHeight(role === 'ADMIN' ? 25 : 30)}
                className="cursor-pointer"
              >
                <ArrowDownIcon />
              </span>
            ) : (
              <span onClick={() => setMaxHeight(20)} className="cursor-pointer">
                <ArrowUpIcon />
              </span>
            )}
          </div>
        )}
      </div>

      <ModalShareCHat
        open={openShareChat}
        handleClose={handleCloseModalShareChat}
        urlShareChat={urlShareChat}
      />
      <ModalDelete
        open={openDelete}
        handleClose={handleModalDelete}
        dataName={labelChat}
        handleDelete={
          !loadingMessage ? () => DeleteItemHistoryChat(idChat) : () => {}
        }
      />
    </>
  );
};

export default CardItemHistoryChat;
