import React, { Suspense } from 'react';
import { ContentWrapper, Wrapper } from 'components/UI/atoms/Wrapper';
import Navbar from './Navbar';
import { Outlet } from 'react-router-dom';

const TemplateWithoutSidebar = () => {
  return (
    <React.Fragment>
      <Wrapper>
        <Navbar hasSidebar={false} />
        <ContentWrapper className="transition-all duration-500 w-full h-full">
          <Suspense fallback={`Loading...`}>
            <Outlet />
          </Suspense>
        </ContentWrapper>
      </Wrapper>
    </React.Fragment>
  );
};

export default TemplateWithoutSidebar;
