import React, { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import ImageChat from 'components/assets/images/Chatbot_Home.png';
import LogoConfluidity from 'components/assets/images/Logo.png';

const TemplateInvitation = () => {
  return (
    <>
      <div className="w-full flex flex-wrap bg-white">
        <div className="w-1/2 min-h-screen flex justify-start items-center">
          <div className="w-full">
            <img
              src={ImageChat}
              alt="image_chat"
              className="w-[40rem] h-[30rem]"
            />
          </div>
        </div>
        <div className="w-1/2 min-h-screen flex flex-col justify-center items-center relative">
          <div className="w-full text-center absolute top-0 mt-4 justify-center">
            <img src={LogoConfluidity} alt="logo" className="inline w-48" />
          </div>
          <div className="w-full flex flex-wrap justify-center items-center space-y-4">
            <Suspense fallback={`Loading...`}>
              <Outlet />
            </Suspense>
          </div>
          <div className="w-full text-center absolute bottom-0 mb-10 justify-center">
            <p className="font-semibold text-sm text-slate-500">
              Privacy & Terms
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default TemplateInvitation;
