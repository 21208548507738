import { parseISO, format } from 'date-fns';

export const formatFileSize = (size) => {
  const i = Math.floor(Math.log(size) / Math.log(1024));
  return (
    (size / Math.pow(1024, i)).toFixed(2) * 1 +
    ' ' +
    ['B', 'KB', 'MB', 'GB', 'TB'][i]
  );
};

export const objectToParams = (obj) => {
  if (!obj) return '';
  const str = Object.keys(obj)
    .filter((key) => obj[key])
    .map(function (key) {
      return key + '=' + obj[key];
    })
    .join('&');
  return str;
};

export const formatDate = (dateString) => {
  if (!dateString) {
    return ''; // Return an empty string or a default value if dateString is invalid
  }

  try {
    const date = parseISO(dateString);
    return format(date, 'dd/MM/yyyy, HH:mm');
  } catch (error) {
    console.error('Error formatting date:', error);
    return ''; // Return an empty string or a default value in case of an error
  }
};
export const formatTime = (dateString) => {
  if (!dateString) {
    return ''; // Return an empty string or a default value if dateString is invalid
  }

  try {
    const date = parseISO(dateString);
    return format(date, 'HH:mm');
  } catch (error) {
    console.error('Error formatting date:', error);
    return ''; // Return an empty string or a default value in case of an error
  }
};

// utils.js
export const preprocessMathContent = (content) => {
  // Replace double backslashes with single backslashes
  content = content.replace(/\\\\/g, '\\');

  // Ensure inline math expressions are properly formatted
  content = content.replace(/\\\(/g, '$');
  content = content.replace(/\\\)/g, '$');

  // Replace \[ and \] with $$
  content = content.replace(/\\\[/g, '$$');
  content = content.replace(/\\\]/g, '$$');

  return content;
};
