import io from 'socket.io-client';
import { Config } from 'utils/initialConfig';

const URL = Config?.REACT_APP_SOCKET_URL;

export const socketCall = io(URL, {
  transports: ['websocket'],
});

export const initialRefectSocket = (userId) => {
  if (socketCall && !socketCall.connected) {
    socketCall.on('connect', () => {
      console.log('Socket connected');
      socketCall.emit('JoinRoomNotification', userId);
    });
  }
};

export const setupIncomingNotifListener = (notif) => {
  socketCall.on('ReceiveNotification', (msg) => {
    // console.log('msg', msg);
    return notif(null, msg);
  });
};

export const disconnectSocket = () => {
  return () => {
    if (socketCall && socketCall.connected) {
      console.log('Cleaning up the socket connection');
      socketCall.off('connect'); // Remove any listeners
      socketCall.disconnect(); // Disconnect the socket
    }
  };
};
