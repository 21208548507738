import React from 'react';
import Modal from '../Modal';
import { CloseIcon } from '../Icons';
import Button from '../Buttons';

const ModalDelete = ({
  open,
  handleClose,
  dataName,
  handleDelete,
  modalType = 'Document',
}) => {
  return (
    <Modal open={open} width="w-1/3">
      <div className="bg-white p-8">
        <div className="w-ful flex justify-between items-center">
          <span className="font-semibold text-xl">Delete {modalType}</span>
          <span onClick={handleClose} className="cursor-pointer">
            <CloseIcon />
          </span>
        </div>
        <div className="w-full my-5 items-center">
          <span>
            Are you sure you want to delete {dataName}? The data you delete
            cannot be recovered.
          </span>
        </div>
        <div className="w-full flex justify-end gap-2">
          <Button text="Cancel" outline={true} onClick={handleClose} />
          {/* <Button text="Delete" onClick={handleDelete} /> */}

          <button
            onClick={handleDelete}
            className={`flex w-auto justify-center items-center gap-2 rounded-lg bg-red-500 px-3 py-2 text-base font-semibold leading-6  text-white shadow-sm hover:bg-red-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600`}
          >
            Delete
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ModalDelete;
