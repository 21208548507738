import React, { useEffect, useState } from 'react';
import Modal from '../atoms/Modal';
import { CloseIcon, CopyPasteIcon, LinkShareIcon } from '../atoms/Icons';
import copy from 'copy-to-clipboard';

const ModalShareCHat = ({ open, handleClose, urlShareChat }) => {
  const [iconCopyStates, setIconCopyStates] = useState(false);
  const [link, setLink] = useState(null);

  useEffect(() => {
    setLink(urlShareChat);
  }, [urlShareChat]);

  const copyToClipboard = () => {
    const text = link;
    copy(text);
    setIconCopyStates(true);

    setTimeout(() => {
      setIconCopyStates(false);
    }, 2000);
  };

  return (
    <Modal open={open} width="w-full md:w-1/3">
      <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
        <div className="w-ful flex justify-between items-center">
          <span className="font-semibold text-xl">Share Chat</span>
          <span onClick={handleClose} className="cursor-pointer">
            <CloseIcon />
          </span>
        </div>
        <div className="w-full flex flex-wrap my-5 items-center space-y-4">
          <div className="w-full">
            <p className="font-medium text-base">Copy the Link</p>
            <p className="font-normal text-sm">
              User can see the chat conversation by clicking the link that you
              given
            </p>
          </div>
          <div className="w-full">
            <div className="w-full flex flex-wrap justify-between">
              <div className="w-3/4 flex items-center gap-1 border-2 border-slate-300 rounded-tl-md rounded-bl-md p-2 ">
                <LinkShareIcon color="#64748b" />{' '}
                <span className="text-xs">{link}</span>
              </div>
              <div
                onClick={() => copyToClipboard()}
                className={`w-1/4 flex items-center text-center justify-center gap-2 ${iconCopyStates ? 'bg-green-200' : 'bg-blue-200'}  cursor-pointer rounded-tr-md rounded-br-md p-2`}
              >
                <CopyPasteIcon color="#7e22ce" height="18" width="18" />{' '}
                <span className="text-purple-700 text-sm font-medium">
                  {iconCopyStates ? 'Copied!' : 'Copy Link'}
                </span>
              </div>
            </div>
            <p className="font-normal text-sm">
              The link will expired after 3 days of sharing
            </p>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ModalShareCHat;
