import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { checkTokenAuth } from 'utils/authUtils';

const ProtectedRoute = () => {
  const isAuthenticated = checkTokenAuth();
  const isAuthorized = isAuthenticated;

  return isAuthorized ? <Outlet /> : <Navigate to="/login" replace />;
};

export default ProtectedRoute;
