import React, { useContext, useEffect, useState } from 'react';
import { Modal, Button as Btn } from 'antd';
import { MessageContext } from 'contexts/GlobalStateMessage';
import {
  DeleteMemoryLogIcon,
  EditMemoryLogIcon,
  SearchIcon,
} from '../atoms/Icons';
import Button from '../atoms/Buttons';
import { useQuery } from 'react-query';
import {
  deleteAllMemoryLog,
  deleteMemoryLog,
  editMemoryLog,
  getListMemoryLog,
} from 'services/memory_log/memory_log';
import { toast } from 'react-toastify';

const ModalEditMemoryLog = ({ open, handleClose, dataLog, refetch }) => {
  const [formData, setFormData] = useState({
    id: '',
    log: '',
  });
  const [errors, setErrors] = useState({
    id: '',
    log: '',
    error: '',
  });

  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      id: dataLog.id,
      log: dataLog.log,
    }));
  }, [dataLog]);

  const handleOk = async () => {
    const { id, log } = formData;
    const newErrors = {};

    if (id === '') {
      newErrors.id = 'Id cannot be empty.';
    }
    if (log === '') {
      newErrors.log = 'Log cannot be empty.';
    }
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      const formDataLog = new FormData();
      formDataLog.append('memory', formData.log);
      try {
        const res = await editMemoryLog(formDataLog, formData.id);
        if (res) {
          handleClose();
          await refetch();
        }
      } catch (error) {
        toast.error(error?.message);
      }
    }
  };

  return (
    <>
      <Modal
        title="Edit Memory"
        style={{ top: 200 }}
        open={open}
        onOk={handleOk}
        onCancel={handleClose}
        footer={[
          <Btn key="cancel" onClick={handleClose}>
            Cancel
          </Btn>,
          <Btn
            key="ok"
            type="primary"
            onClick={handleOk}
            className="bg-[#4A3695]" // Apply the custom class
          >
            Save
          </Btn>,
        ]}
      >
        <div className="w-full p-4">
          <textarea
            value={formData.log}
            onChange={(e) => {
              setFormData((prev) => ({
                ...prev, // Spread the previous form data
                log: e.target.value, // Update the "log" field with the new value
              }));
            }}
            placeholder="Log..."
            className="w-full h-32 p-2 border border-gray-300 rounded-md resize-none focus:outline-none focus:ring-2 focus:ring-blue-500"
          ></textarea>
          {errors.log && (
            <div className="w-full justify-start items-start flex">
              <span className="text-red-500">{errors.log}</span>
            </div>
          )}
        </div>
      </Modal>
    </>
  );
};

const ModalDeleteMemoryLog = ({
  open,
  handleClose,
  dataLog,
  refetch,
  // clear,
}) => {
  const handleOk = async () => {
    try {
      await deleteMemoryLog(dataLog.id);
      handleClose();
      await refetch();
    } catch (error) {
      toast.error(error?.message);
    }
  };

  return (
    <>
      <Modal
        title="Delete Memory"
        style={{ top: 200 }}
        open={open}
        onOk={handleOk}
        onCancel={handleClose}
        footer={[
          <Btn key="cancel" onClick={handleClose}>
            Cancel
          </Btn>,
          <Btn
            key="ok"
            type="primary"
            onClick={handleOk}
            className="bg-red-500 hover:bg-red-600" // Apply the custom class
          >
            Delete
          </Btn>,
        ]}
      >
        <div className="w-full p-4">
          <p>
            Are you sure you want to delete the memory? The data you delete
            cannot be recovered.
          </p>
        </div>
      </Modal>
    </>
  );
};

const ModalClearMemoryLog = ({ open, handleClose, refetch }) => {
  const handleOk = async () => {
    try {
      const res = await deleteAllMemoryLog();
      if (res) {
        handleClose();
        await refetch();
      }
    } catch (error) {
      toast.error(error?.message);
    }
  };

  return (
    <>
      <Modal
        title="Clear Memory"
        style={{ top: 200 }}
        open={open}
        onOk={handleOk}
        onCancel={handleClose}
        footer={[
          <Btn key="cancel" onClick={handleClose}>
            Cancel
          </Btn>,
          <Btn
            key="ok"
            type="primary"
            onClick={handleOk}
            className="bg-red-500 hover:bg-red-600" // Apply the custom class
          >
            Delete
          </Btn>,
        ]}
      >
        <div className="w-full p-4">
          <p>
            Are you sure you want to delete the memory? The data you delete
            cannot be recovered.
          </p>
        </div>
      </Modal>
    </>
  );
};

const ModalMemoryLog = () => {
  const { openModalMemoryLog, setOpenModalMemoryLog } =
    useContext(MessageContext);
  const [openEditMemoryLog, setOpenEditMemoryLog] = useState(false);
  const [openDeleteMemoryLog, setOpenDeleteMemoryLog] = useState(false);
  const [openClearMemoryLog, setOpenClearMemoryLog] = useState(false);
  const [clear, setClear] = useState(false);
  const [expandedIndex, setExpandedIndex] = useState(null);

  const [dataLog, setDataLog] = useState({
    id: '',
    log: '',
  });

  const [filter, setFilter] = useState({
    page: 1,
    limit: '',
    search: '',
    sort: '',
  });

  const toggleExpand = (index) => {
    if (expandedIndex === index) {
      setExpandedIndex(null); // Collapse if already expanded
    } else {
      setExpandedIndex(index); // Expand the clicked one
    }
  };

  const { data, refetch } = useQuery(
    ['fetchListMemoryLog', filter],
    () => getListMemoryLog(filter),
    {
      enabled: true,
    }
  );

  useEffect(() => {
    refetch();
  }, [openModalMemoryLog]);

  const handleModalEditMemoryLog = (id, log) => {
    setDataLog((prevDataLog) => ({
      ...prevDataLog,
      id: id,
      log: log,
    }));
    setOpenEditMemoryLog(!openEditMemoryLog);
  };

  const handleModalDeleteMemoryLog = (id, log, clear) => {
    setClear(clear);
    setDataLog((prevDataLog) => ({
      ...prevDataLog,
      id: id,
      log: log,
    }));
    setOpenDeleteMemoryLog(!openDeleteMemoryLog);
  };

  const handleModalClearMemoryLog = () => {
    setOpenClearMemoryLog(!openClearMemoryLog);
  };

  const handleCancel = () => {
    setOpenModalMemoryLog(false);
  };

  const handleInputChange = (e) => {
    setFilter((prevFilter) => ({
      ...prevFilter,
      search: e.target.value,
    }));
  };

  return (
    <>
      <Modal
        title="Memory Log"
        open={openModalMemoryLog}
        onCancel={handleCancel}
        maskClosable={false} // Prevent closing by clicking outside the modal
        keyboard={false}
        width={800}
        footer={null}
      >
        <div className="bg-white">
          <div className="relative w-full my-5 py-3">
            {/* Search Icon */}
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <SearchIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </div>

            {/* Input Field */}
            <input
              type="text"
              value={filter.search}
              onChange={
                data?.data?.memory_logs.length > 0
                  ? handleInputChange
                  : () => {}
              }
              className="block w-full pl-10 pr-3 py-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              placeholder="Search memory" // This is the text placeholder
            />
          </div>
          <div className="w-full my-5 items-center space-y-4 overflow-y-auto h-80">
            {data?.data?.memory_logs.length > 0 ? (
              <div className="w-full border-2 rounded-lg">
                {data?.data?.memory_logs?.map((item, index) => (
                  <>
                    <div
                      key={index}
                      className="w-full border-b-2 p-4 flex flex-wrap justify-between"
                    >
                      <div className="w-full flex justify-between">
                        <span>
                          {expandedIndex === index
                            ? item?.memory // Show full memory if expanded
                            : item?.memory?.length > 200
                              ? item?.memory?.substring(0, 200) + '...' // Show truncated memory
                              : item?.memory}
                        </span>

                        <div className="flex items-center gap-2">
                          <span
                            onClick={() => {
                              handleModalEditMemoryLog(item.id, item.memory);
                            }}
                            className="cursor-pointer"
                          >
                            <EditMemoryLogIcon />
                          </span>
                          <span
                            onClick={() => {
                              handleModalDeleteMemoryLog(
                                item.id,
                                item.memory,
                                false
                              );
                            }}
                            className="cursor-pointer"
                          >
                            <DeleteMemoryLogIcon />
                          </span>
                        </div>
                      </div>
                      {item?.memory?.length > 200 && (
                        <p
                          onClick={() => toggleExpand(index)} // Toggle expand on click
                          className="w-full cursor-pointer text-sm font-medium text-blue-500"
                        >
                          {expandedIndex === index ? 'See less' : 'See more'}
                        </p>
                      )}
                    </div>
                  </>
                ))}
              </div>
            ) : (
              <div className="w-full flex justify-center text-base">
                <p>Empty Memory Log</p>
              </div>
            )}
          </div>

          {data?.data?.memory_logs.length > 0 && (
            <div className="w-full flex justify-end">
              <Button
                text="Clear Memory"
                outline={true}
                textColor="text-red-500"
                onClick={handleModalClearMemoryLog}
              />
            </div>
          )}
        </div>

        <ModalEditMemoryLog
          open={openEditMemoryLog}
          handleClose={handleModalEditMemoryLog}
          dataLog={dataLog}
          refetch={refetch}
        />
        <ModalDeleteMemoryLog
          open={openDeleteMemoryLog}
          handleClose={handleModalDeleteMemoryLog}
          dataLog={dataLog}
          refetch={refetch}
          clear={clear}
        />
        <ModalClearMemoryLog
          open={openClearMemoryLog}
          handleClose={handleModalClearMemoryLog}
          refetch={refetch}
        />
      </Modal>
    </>
  );
};
export default ModalMemoryLog;
