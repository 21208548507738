import { Axios } from 'utils/Axios';

export const getNotificationList = async (limit, page) => {
  try {
    const URL = `/notification?limit=${limit}&page=${page}&sort=created_at- `;
    const response = await Axios.get(URL);
    return response?.data;
  } catch (error) {
    return error;
  }
};

export const regenerateChat = async (chatId) => {
  try {
    const URL = `/chats/message/regenerate `;
    const response = await Axios.post(URL, { chat_id: chatId });
    return response?.data;
  } catch (error) {
    return error;
  }
};

export const editIsRead = async (id) => {
  try {
    const URL = `/notification/read`;
    const response = await Axios.post(URL, { notification_ids: [id] });
    return response?.data;
  } catch (error) {
    return error;
  }
};
